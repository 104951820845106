import { parseDataKey } from "src/common/util";
import { CertifySaleSuccessAction, CreateSaleSuccessAction, ErrorAction, GetSaleByIdSuccessAction, GetSalePaymentRecordsSuccessAction, GetSaleRecordsSuccessAction, GetSalesReportSuccessAction } from "./action";
import { ABOLISH_SALE_API, ABOLISH_SALE_API_ERROR, ABOLISH_SALE_API_SUCCESS, CERTIFY_SALE_API, CERTIFY_SALE_API_ERROR, CERTIFY_SALE_API_SUCCESS, CLEAN_SALE_PAYMENTS, CLEAR_CURRENT_SALE, CREATE_SALE_API, CREATE_SALE_API_ERROR, CREATE_SALE_API_SUCCESS, CREATE_SALE_PAYMENT_API, CREATE_SALE_PAYMENT_API_ERROR, CREATE_SALE_PAYMENT_API_SUCCESS, GET_SALES_REPORT_API, GET_SALES_REPORT_API_SUCCESS, GET_SALE_BY_ID_API, GET_SALE_BY_ID_API_ERROR, GET_SALE_BY_ID_API_SUCCESS, GET_SALE_PAYMENTS_API, GET_SALE_PAYMENTS_API_ERROR, GET_SALE_PAYMENTS_API_SUCCESS, GET_SALE_RECORDS_API, GET_SALE_RECORDS_API_ERROR, GET_SALE_RECORDS_API_SUCCESS } from "./actionTypes";
import { SaleError, SaleErrorOrigin } from "./types";
import { Sale, SalesReport } from "src/common/models/sale";
import { ReportView } from "src/common/models/report";
import { SalePayment } from "src/common/models/salePayment";

export interface SaleReportData {
    view: ReportView
    data: SalesReport
}

export interface SaleState {
    error?: SaleError
    createSaleSuccess: boolean
    upsertedSale?: Sale
    sales?: Sale[]
    currentSale?: Sale
    certifySaleSuccess: boolean
    report?: SaleReportData
    createSalePaymentSuccess: boolean
    salePayments?: SalePayment[]
    abolishSaleSuccess: boolean
}

const initialState: SaleState = {
    createSaleSuccess: false,
    certifySaleSuccess: false,
    createSalePaymentSuccess: false,
    abolishSaleSuccess: false,
}

const saleReducer = (state: SaleState = initialState, action: any): SaleState => {
    switch (action.type) {
        case CREATE_SALE_API: {
            return {
                ...state,
                error: undefined,
                createSaleSuccess: false,
            }
        }
        case CREATE_SALE_API_SUCCESS: {
            const successAction = action as CreateSaleSuccessAction;

            return {
                ...state,
                upsertedSale: successAction.sale,
                createSaleSuccess: true,
            }
        }
        case CREATE_SALE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.CREATE_SALE,
                    detail: errorAction.error
                }
            }
        }
        case GET_SALE_RECORDS_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case GET_SALE_RECORDS_API_SUCCESS: {
            const successAction = action as GetSaleRecordsSuccessAction;

            return {
                ...state,
                sales: parseDataKey(successAction.sales),
            }
        }
        case GET_SALE_RECORDS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.GET_SALE_RECORDS,
                    detail: errorAction.error,
                }
            }
        }
        case GET_SALE_BY_ID_API: {
            return {
                ...state,
                error: undefined,
                currentSale: undefined,
            }
        }
        case GET_SALE_BY_ID_API_SUCCESS: {
            const successAction = action as GetSaleByIdSuccessAction;

            return {
                ...state,
                currentSale: successAction.sale,
            }
        }
        case GET_SALE_BY_ID_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.GET_SALE_BY_ID,
                    detail: errorAction.error,
                }
            }
        }
        case CLEAR_CURRENT_SALE: {
            return {
                ...state,
                currentSale: undefined,
            }
        }
        case CERTIFY_SALE_API: {
            return {
                ...state,
                error: undefined,
                certifySaleSuccess: false,
            }
        }
        case CERTIFY_SALE_API_SUCCESS: {
            const successAction = action as CertifySaleSuccessAction;

            return {
                ...state,
                upsertedSale: successAction.sale,
                certifySaleSuccess: true,
            }
        }
        case CERTIFY_SALE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.CERTIFY_SALE,
                    detail: errorAction.error
                }
            }
        }
        case GET_SALES_REPORT_API: {
            return {
                ...state,
                error: undefined,
                report: undefined,
            }
        }
        case GET_SALES_REPORT_API_SUCCESS: {
            const successAction = action as GetSalesReportSuccessAction;

            return {
                ...state,
                report: {
                    view: successAction.view,
                    data: parseDataKey(successAction.report),
                }
            }
        }
        case GET_SALES_REPORT_API_SUCCESS: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.GET_SALES_REPORT,
                    detail: errorAction.error,
                }
            }
        }
        case CREATE_SALE_PAYMENT_API: {
            return {
                ...state,
                error: undefined,
                createSalePaymentSuccess: false,
            }
        }
        case CREATE_SALE_PAYMENT_API_SUCCESS: {
            return {
                ...state,
                createSalePaymentSuccess: true,
            }
        }
        case CREATE_SALE_PAYMENT_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.CREATE_SALE_PAYMENT,
                    detail: errorAction.error,
                },
            }
        }
        case GET_SALE_PAYMENTS_API: {
            return {
                ...state,
                error: undefined,
                salePayments: undefined,
            }
        }
        case GET_SALE_PAYMENTS_API_SUCCESS: {
            const successAction = action as GetSalePaymentRecordsSuccessAction;

            return {
                ...state,
                salePayments: parseDataKey(successAction.salePayments),
            }
        }
        case GET_SALE_PAYMENTS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.GET_SALE_PAYMENTS,
                    detail: errorAction.error
                }
            }
        }
        case CLEAN_SALE_PAYMENTS: {
            return {
                ...state,
                salePayments: undefined,
            }
        }
        case ABOLISH_SALE_API: {
            return {
                ...state,
                error: undefined,
                abolishSaleSuccess: false,
            }
        }
        case ABOLISH_SALE_API_SUCCESS: {
            return {
                ...state,
                abolishSaleSuccess: true,
            }
        }
        case ABOLISH_SALE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.ABOLISH_SALE,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default saleReducer;