import { Languages } from "src/common/descriptions/types";
import { PurchasePaymentRecordsDescriptions } from "./types";

const DescriptionsEnUs: PurchasePaymentRecordsDescriptions = {
    TITLE: "Purchase Payment Records",
    TABLE: {
        ACTION_BUTTON: "Get Records",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        TITLE: "Payment Records",
        TOTAL: "Total",
        CASH_AMOUNT: "Cash Amount",
        CASH_DATE: "Cash Date",
        CASH_REF: "Cash Ref",
        TRANS_AMOUNT: "Trans Amount",
        TRANS_DATE: "Trans Date",
        TRANS_REF: "Trans Ref",
        CREDIT_NOTE_AMOUNT: "Credit Note Amount",
        CREDIT_NOTE_DATE: "Credit Note Date",
        CREDIT_NOTE_REF: "Credit Note Ref",
        NO: "No",
        PURCHASE_DOCUMENT: "Document",
        SUPPLIER: "Supplier",
        DATETIME: "Datetime",
        PURCHASE_TOTAL: "Purchase Total",
        AMOUNT_PENDING: "Amount Pending",
    },
    TOTAL: "TOTAL",
    EXPORT_DATA: "Export data",
    PRINT: "Print",
}

const DescriptionsEsUs: PurchasePaymentRecordsDescriptions = {
    TITLE: "Registro de Pagos a Proveedores",
    TABLE: {
        ACTION_BUTTON: "Ver Registros",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando datos...",
    },
    TABLE_COLUMNS: {
        TITLE: "Historial de Pagos",
        TOTAL: "Total",
        CASH_AMOUNT: "Efectivo Monto",
        CASH_DATE: "Eectivo Fecha",
        CASH_REF: "Efectivo Ref",
        TRANS_AMOUNT: "Trans Monto",
        TRANS_DATE: "Trans Fecha",
        TRANS_REF: "Trans Ref",
        CREDIT_NOTE_AMOUNT: "Nota Crédito Monto",
        CREDIT_NOTE_DATE: "Nota Crédito Fecha",
        CREDIT_NOTE_REF: "Nota Crédito Ref",
        NO: "No",
        PURCHASE_DOCUMENT: "Documento",
        SUPPLIER: "Proveedor",
        DATETIME: "Fecha",
        PURCHASE_TOTAL: "Total Facturado",
        AMOUNT_PENDING: "Saldo",
    },
    TOTAL: "TOTAL",
    EXPORT_DATA: "Exportar datos",
    PRINT: "Imprimir",
}

export const purchasePaymentRecordsDictionary = {}
purchasePaymentRecordsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
purchasePaymentRecordsDictionary[Languages.SPANISH_US] = DescriptionsEsUs