import { Languages } from "../../types";
import { SidebarDescriptions } from "./types";

const DescriptionsEnUs: SidebarDescriptions = {
    POS: {
        TITLE: 'Point of Sale',
        ITEMS: {
            CREATE_SALE: {
                DISPLAY_NAME: 'Create Sale',
                TOOLTIP: '',
            },
            SALE_RECORDS: {
                DISPLAY_NAME: 'Sales Records',
                TOOLTIP: '',
            },
            PRODUCTS_STOCK: {
                DISPLAY_NAME: 'Product Stock',
                TOOLTIP: '',
            },
            CUSTOMER_PAYMENT_RECORDS: {
                DISPLAY_NAME: 'Sale Payment Records',
                TOOLTIP: '',
            },
            CUSTOMERS: {
                DISPLAY_NAME: 'Customers',
                TOOLTIP: '',
            },
        },
    },
    ADMINISTRATION: {
        TITLE: 'Administration',
        ITEMS: {
            CREATE_PURCHASE: {
                DISPLAY_NAME: 'Create Purchase',
                TOOLTIP: '',
            },
            PRODUCTS: {
                DISPLAY_NAME: 'Products',
                TOOLTIP: '',
            },
            CREATE: {
                DISPLAY_NAME: 'Create Product',
                TOOLTIP: '',
            },
            PURCHASE_RECORDS: {
                DISPLAY_NAME: 'Purchase Records',
                TOOLTIP: '',
            },
            ACCOUNT_PAYABLES: {
                DISPLAY_NAME: 'Account Payables',
                TOOLTIP: '',
            },
            ACCOUNT_RECEIVABLES: {
                DISPLAY_NAME: 'Account Receivables',
                TOOLTIP: '',
            },
            SUPPLIERS: {
                DISPLAY_NAME: 'Suppliers',
                TOOLTIP: '',
            },
            STOCK_REGULARIZATIONS: {
                DISPLAY_NAME: "Stock Regularizations",
                TOOLTIP: '',
            },
            SUPPLIER_PAYMENT_RECORDS: {
                DISPLAY_NAME: "Supplier Payment Records",
                TOOLTIP: '',
            },
        },
    },
    ROUTING: {
        TITLE: 'Internal Route',
        ITEMS: {
            CREATE_SALE: {
                DISPLAY_NAME: 'Create Sale',
                TOOLTIP: '',
            },
        },
    },
    MAINTENANCE: {
        TITLE: "Maintenance",
        ITEMS: {
            SETTINGS: {
                DISPLAY_NAME: "Settings",
                TOOLTIP: "",
            },
            PRODUCTS_SETTINGS: {
                DISPLAY_NAME: "Product Settings",
                TOOLTIP: "",
            },
        },
    },
    REPORTS: {
        TITLE: "Reports",
        ITEMS: {
            KARDEX: {
                DISPLAY_NAME: "Products Kardex",
                TOOLTIP: "",
            },
            UNITS_SOLD: {
                DISPLAY_NAME: "Units Sold",
                TOOLTIP: "",
            },
            SALES: {
                DISPLAY_NAME: "Sales",
                TOOLTIP: "",
            },
            UNITS_SOLD_UTILITY: {
                DISPLAY_NAME: "Units Sold Utility",
                TOOLTIP: "",
            },
            STOCK_VALUE: {
                DISPLAY_NAME: "Stock Value",
                TOOLTIP: "",
            },
        },
    },
    HOME: {
        TITLE: 'Home',
        ITEMS: {
        },
    },
}

const DescriptionsEsUs: SidebarDescriptions = {
    POS: {
        TITLE: 'Punto de Venta',
        ITEMS: {
            CREATE_SALE: {
                DISPLAY_NAME: 'Crear Venta',
                TOOLTIP: '',
            },
            SALE_RECORDS: {
                DISPLAY_NAME: 'Registro de Ventas',
                TOOLTIP: '',
            },
            PRODUCTS_STOCK: {
                DISPLAY_NAME: 'Existencia de Productos',
                TOOLTIP: '',
            },
            CUSTOMER_PAYMENT_RECORDS: {
                DISPLAY_NAME: 'Pagos Recibidos',
                TOOLTIP: '',
            },
            CUSTOMERS: {
                DISPLAY_NAME: 'Clientes',
                TOOLTIP: '',
            },
        },
    },
    ADMINISTRATION: {
        TITLE: 'Administración',
        ITEMS: {
            CREATE_PURCHASE: {
                DISPLAY_NAME: 'Crear Compra',
                TOOLTIP: '',
            },
            PRODUCTS: {
                DISPLAY_NAME: 'Productos',
                TOOLTIP: '',
            },
            CREATE: {
                DISPLAY_NAME: 'Crear Producto',
                TOOLTIP: '',
            },
            PURCHASE_RECORDS: {
                DISPLAY_NAME: 'Registro de Compras',
                TOOLTIP: '',
            },
            ACCOUNT_PAYABLES: {
                DISPLAY_NAME: 'Cuentas por Pagar',
                TOOLTIP: '',
            },
            ACCOUNT_RECEIVABLES: {
                DISPLAY_NAME: 'Cuentas por Cobrar',
                TOOLTIP: '',
            },
            SUPPLIERS: {
                DISPLAY_NAME: 'Proveedores',
                TOOLTIP: '',
            },
            STOCK_REGULARIZATIONS: {
                DISPLAY_NAME: "Regularization de existenias",
                TOOLTIP: '',
            },
            SUPPLIER_PAYMENT_RECORDS: {
                DISPLAY_NAME: "Registro de Pagos",
                TOOLTIP: '',
            },
        },
    },
    ROUTING: {
        TITLE: 'Ruta Interna',
        ITEMS: {
            CREATE_SALE: {
                DISPLAY_NAME: 'Crear Venta',
                TOOLTIP: '',
            },
        },
    },
    MAINTENANCE: {
        TITLE: "Mantenimiento",
        ITEMS: {
            SETTINGS: {
                DISPLAY_NAME: "Configuración",
                TOOLTIP: "",
            },
            PRODUCTS_SETTINGS: {
                DISPLAY_NAME: "Propiedades de Productos",
                TOOLTIP: "",
            },
        },
    },
    REPORTS: {
        TITLE: "Reportes",
        ITEMS: {
            KARDEX: {
                DISPLAY_NAME: "Kardex de Productos",
                TOOLTIP: "",
            },
            UNITS_SOLD: {
                DISPLAY_NAME: "Unidades Vendidas",
                TOOLTIP: "",
            },
            SALES: {
                DISPLAY_NAME: "Ventas",
                TOOLTIP: "",
            },
            UNITS_SOLD_UTILITY: {
                DISPLAY_NAME: "Utilidad Unidades Vendidas",
                TOOLTIP: "",
            },
            STOCK_VALUE: {
                DISPLAY_NAME: "Inventario Valorizado",
                TOOLTIP: "",
            },
        },
    },
    HOME: {
        TITLE: 'Inicio',
        ITEMS: {
        },
    },
}

const sidebarDictionary = {}
sidebarDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
sidebarDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getSidebarDescriptions = (systemLang: Languages): SidebarDescriptions => (
    sidebarDictionary[systemLang]
)