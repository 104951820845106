import { ApiActionTypes, buildApiTypeAction } from "src/common/apiLoader/apiLoaderUtils";
import { SystemConstants } from "src/common/constants";

const LOGIN = 'LOGIN';
export const LOGIN_API = buildApiTypeAction(LOGIN, ApiActionTypes.REQUEST)
export const LOGIN_SUCCESS = buildApiTypeAction(LOGIN, ApiActionTypes.SUCCESS)
export const LOGIN_ERROR = buildApiTypeAction(LOGIN, ApiActionTypes.ERROR)

const LOGOUT = 'LOGOUT';
export const LOGOUT_API = LOGOUT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;

const CREATE_AUTH_TOKEN = 'CREATE_AUTH_TOKEN';
export const CREATE_AUTH_TOKEN_API = buildApiTypeAction(CREATE_AUTH_TOKEN, ApiActionTypes.REQUEST)
export const CREATE_AUTH_TOKEN_API_SUCCESS = buildApiTypeAction(CREATE_AUTH_TOKEN, ApiActionTypes.SUCCESS)
export const CREATE_AUTH_TOKEN_API_ERROR = buildApiTypeAction(CREATE_AUTH_TOKEN, ApiActionTypes.ERROR)

const VALIDATE_AUTH_TOKEN = 'VALIDATE_AUTH_TOKEN';
export const VALIDATE_AUTH_TOKEN_API = buildApiTypeAction(VALIDATE_AUTH_TOKEN, ApiActionTypes.REQUEST)
export const VALIDATE_AUTH_TOKEN_API_SUCCESS = buildApiTypeAction(VALIDATE_AUTH_TOKEN, ApiActionTypes.SUCCESS)
export const VALIDATE_AUTH_TOKEN_API_ERROR = buildApiTypeAction(VALIDATE_AUTH_TOKEN, ApiActionTypes.ERROR)