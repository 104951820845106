import { ColumnsType } from "antd/es/table"
import { SearchableTable } from "src/components/SearchableTable"
import { Button, Space, Tooltip } from "antd"
import { EditOutlined, ProfileOutlined } from '@ant-design/icons'
import { ReduxProps } from "./types"
import { Customer } from "src/common/models/customer"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { useEffect } from "react"
import { getCustomersApi } from "../state/action"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_CUSTOMERS_API } from "../state/actionTypes"
import { ColumnsKeys, TABLE_COLUMNS } from "./config"
import { SystemDescriptions } from "src/common/descriptions"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import useUserPermission from "src/hooks/useUserPermissions"
import { useNavigate } from "react-router"
import { Urls } from "src/common/urls"
import { PageCard } from "src/common/styles/styles"

export const Customers = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isUserAllowedToPerformAdminActions: userAdminActions } = useUserPermission()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        customers: state.customer.customers,
        isLoadingCustomers: serviceLoading(state, [GET_CUSTOMERS_API]),
    }))

    useEffect(() => {
        dispatch(getCustomersApi())
    }, [])

    const descriptions = SystemDescriptions.PAGES.CUSTOMER.CUSTOMERS
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const columns: ColumnsType<Customer> = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: ColumnsKeys.ACTIONS,
            key: ColumnsKeys.ACTIONS,
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Customer) => {
                const handleEditClick = (customerId: number) => {
                    navigate(Urls.FRONTEND.CUSTOMER.EDIT_PAGE(customerId))
                }

                const handleViewClick = (customerId: number) => {
                    navigate(Urls.FRONTEND.CUSTOMER.CUSTOMER_PROFILE_PAGE(customerId))
                }

                return <Space size="middle">
                    <Tooltip title={commonDescriptions.ACTIONS.VIEW} >
                        <Button type="primary" ghost shape="circle" size="small"
                            icon={<ProfileOutlined rev={undefined} />}
                            onClick={() => handleViewClick(record.id!)}
                        />
                    </Tooltip>
                    {
                        userAdminActions
                            ?
                            <Tooltip title={commonDescriptions.ACTIONS.EDIT} >
                                <Button type="primary" ghost shape="circle" size="small"
                                    icon={<EditOutlined rev={undefined} />}
                                    onClick={() => handleEditClick(record.id!)}
                                />
                            </Tooltip>
                            : null
                    }
                </Space>
            },
        },
    ]

    const handleTableActionClick = () => {
        navigate(Urls.FRONTEND.CUSTOMER.CREATE)
    }

    return (
        < PageCard
            size="small"
            title={descriptions.TITLE}
        >
            <SearchableTable
                showSearch
                showActionButton={userAdminActions}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                actionButtonOnClick={handleTableActionClick}
                showLoader={reduxProps.isLoadingCustomers}
                items={reduxProps.customers || []}
                tableColumns={columns}
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
            />
        </PageCard>
    )
}
