import { Customer } from "src/common/models/customer"
import { CreateCustomerForm } from "../../createCustomer/CreateCustomerForm"

interface Props {
    customer?: Customer
}

export const CustomerInformation = (props: Props) => {

    const onFinish = (customer: Partial<Customer>) => {
        console.log("update customer", customer.id)
        alert("Under construction")
    }

    return (
        <CreateCustomerForm
            onFinish={onFinish}
            onCancel={() => { }}
            initialValues={props.customer}
        />
    )
}
