import { Action } from 'redux'
import * as Actions from './actionTypes';
import { User } from 'src/common/models/user';
import { buildErrorAction } from 'src/common/apiLoader/state/actions';
import { AuthTokenRequest } from 'src/common/models/auth';

export interface LoginAction extends Action {
    username: string;
    password: string;
}

export interface LoginSuccessAction extends Action {
    user: User;
    accessToken?: string
}

export interface ErrorAction extends Action {
    error?
}

export interface LogoutAction extends Action { }

export const login = (username: string, password: string): LoginAction => ({
    type: Actions.LOGIN_API,
    username,
    password
});

export const loginSuccess = (user: User): LoginSuccessAction => ({
    type: Actions.LOGIN_SUCCESS,
    user,
})

export const loginError = (error): ErrorAction => ({
    type: Actions.LOGIN_ERROR,
    error,
});

export const logout = (): LogoutAction => ({
    type: Actions.LOGOUT_API
});

export interface CreateAuthTokenAction extends Action {
    data: AuthTokenRequest
}
export interface CreateAuthTokenSuccessAction extends Action {
    success: boolean
}
export const createAuthTokenApi = (data: AuthTokenRequest): CreateAuthTokenAction => ({
    type: Actions.CREATE_AUTH_TOKEN_API,
    data,
});
export const createAuthTokenSuccess = (success: boolean): CreateAuthTokenSuccessAction => ({
    type: Actions.CREATE_AUTH_TOKEN_API_SUCCESS,
    success,
})
export const createAuthTokenError = (error?: any): ErrorAction => buildErrorAction(
    Actions.CREATE_AUTH_TOKEN_API_ERROR,
    error,
)

export interface ValidateAuthTokenAction extends Action {
    data: AuthTokenRequest
}
export interface ValidateAuthTokenSuccessAction extends Action {
    success: boolean
}
export const validateAuthTokenApi = (data: AuthTokenRequest): ValidateAuthTokenAction => ({
    type: Actions.VALIDATE_AUTH_TOKEN_API,
    data,
});
export const validateAuthTokenSuccess = (success: boolean): ValidateAuthTokenSuccessAction => ({
    type: Actions.VALIDATE_AUTH_TOKEN_API_SUCCESS,
    success,
})
export const validateAuthTokenError = (error?: any): ErrorAction => buildErrorAction(
    Actions.VALIDATE_AUTH_TOKEN_API_ERROR,
    error,
)