import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { PurchasePayment } from "src/common/models/purchasePayment"
import { SearchableTable } from "src/components/SearchableTable"
import { GET_PURCHASE_PAYMENTS_API } from "src/features/Purchase/state/actionTypes"
import { RootState } from "src/state/reducer"
import { TABLE_COLUMNS } from "./config"
import moment from "moment"
import dayjs from 'dayjs'
import { useEffect, useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import { SystemConstants } from "src/common/constants"
import { getPurchasePaymentRecordsApi } from "src/features/Purchase/state/action"
import { PageCard } from "src/common/styles/styles"
import { SystemDescriptions } from "src/common/descriptions"
import { Dropdown, MenuProps } from "antd"
import { XlsExporter } from "src/common/XlsExporter"
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { formatToCurrency, formatToDisplayDate } from "src/common/util"

interface ReduxProps {
    isLoadingData: boolean
    purchasePayments?: PurchasePayment[]
}

export const PurchasePayments = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        fetchSaleRecords()
    }, [])

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const handleDateRangesChange = (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_PURCHASE_PAYMENTS_API]),
        purchasePayments: state.purchase.purchasePayments,
    }))

    const descriptions = SystemDescriptions.PAGES.PURCHASE.PURHCASE_PAYMENT_RECORDS

    const tblColumns = [
        ...TABLE_COLUMNS,
    ]

    const fetchSaleRecords = () => {
        dispatch(getPurchasePaymentRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const handleActionButtonClick = () => {
        fetchSaleRecords()
    }

    const buildReportData = () => {
        return reduxProps?.purchasePayments?.map((reportItem: PurchasePayment, idx: number) => {

            const references: string[] = []

            if (reportItem.cashRef) {
                references.push(reportItem.cashRef)
            }
            if (reportItem.transRef) {
                references.push(reportItem.transRef)
            }
            if (reportItem.creaditNoteRef) {
                references.push(reportItem.creaditNoteRef)
            }

            return {
                ID: (idx + 1),
                DOCUMENTO: reportItem.document,
                PROVEEDOR: reportItem.supplier,
                FECHA: formatToDisplayDate(reportItem.datetime),
                TOTAL_FACTURADO: formatToCurrency(reportItem.purchaseAmount?.toString() || '0'),
                EFECTIVO: formatToCurrency(reportItem.cashAmount?.toString() || '0'),
                BANCOS: formatToCurrency(reportItem.transAmount?.toString() || '0'),
                NOTA_CREDITO: formatToCurrency(reportItem.creditNoteAmount?.toString() || '0'),
                SALDO: formatToCurrency(reportItem.amountPending?.toString() || '0'),
                OBSERVACIONES: references.length > 0 ? references.join(', ') : "NA",
            }
        }) ?? []
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "EXPORT_EXCEL") {
            const exporter = new XlsExporter({
                sheetPrefixName: "Report",
                documentName: "SupplierPaymentsReport",
                appendTimestamp: true,
            })

            exporter.exportData(buildReportData())
        }
    };

    const items: MenuProps['items'] = [
        {
            label: descriptions.EXPORT_DATA,
            key: 'EXPORT_EXCEL',
            icon: <FileExcelOutlined rev={undefined} />,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        alert("Under construction")
        // if (!reduxProps.report || !reduxProps.company) {
        //     return
        // }

        // UnitSoldReport({
        //     data: reduxProps.report,
        //     dateFrom: dateRanges.startDate,
        //     dateTo: dateRanges.endDate,
        //     company: reduxProps.company,
        //     type: "REGULAR",
        // })
    };

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Dropdown.Button
                size="small"
                menu={menuProps}
                onClick={handleButtonClick}
                disabled={!reduxProps.purchasePayments || reduxProps.purchasePayments.length === 0}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }} >
                    {<PrinterOutlined rev={undefined} />} {descriptions.PRINT}
                </div>
            </Dropdown.Button>
        </div>
    }

    return (
        <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.purchasePayments ?? []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs().startOf('month'), dayjs()]}
                actionButtonOnClick={handleActionButtonClick}
            />
        </PageCard>
    )
}
