import { call, put, takeLatest, } from 'redux-saga/effects'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { Urls } from 'src/common/urls'
import { SessionData } from 'src/session/types'
import { storeSessionData } from 'src/session/sessionStore'
import { NetworkingProvider } from '../networking/networkingProvider'
import { getErrorFromResponse } from '../networking/util'

function* loginApi(action: Actions.LoginAction) {
    try {
        const body = {
            username: action.username,
            password: action.password
        }

        const response = yield call(NetworkingProvider.getInstance().getLoginInterface().post, Urls.BACKEND.LOGIN(), body)

        const sessionData: SessionData = {
            user: response.data.user,
            accessToken: response.data.accessToken
        }
        storeSessionData(sessionData)
        const result = yield JSON.parse(JSON.stringify(response.data.user))
        yield put(Actions.loginSuccess(result))

    } catch (err: any) {
        if (err?.response?.status === 401) { // 401 - UNAUTHORIZED
            yield put(Actions.loginError("Usuario/contraseña incorrectos, intenta nuevamente."))
        } else {
            yield put(Actions.loginError(JSON.stringify(err.response)))
        }
    }
}

function* createAuthTokenApi(action: Actions.CreateAuthTokenAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.AUTH.CREATE_AUTH_TOKEN, action.data)
        yield put(Actions.createAuthTokenSuccess(response.data))
    } catch (err) {
        yield put(Actions.createAuthTokenError(getErrorFromResponse(err)));
    }
}

function* validateAuthTokenApi(action: Actions.ValidateAuthTokenAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.AUTH.VALIDATE_AUTH_TOKEN, action.data)
        yield put(Actions.validateAuthTokenSuccess(response.data))
    } catch (err) {
        yield put(Actions.validateAuthTokenError(getErrorFromResponse(err)));
    }
}

export default function* securitySagas() {
    yield takeLatest(ActionTypes.LOGIN_API, loginApi)
    yield takeLatest(ActionTypes.CREATE_AUTH_TOKEN_API, createAuthTokenApi)
    yield takeLatest(ActionTypes.VALIDATE_AUTH_TOKEN_API, validateAuthTokenApi)
}