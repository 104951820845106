import * as _ from 'lodash';
import { Action } from 'redux';
import { ErrorAction, LoginSuccessAction } from './action';
import * as Actions from './actionTypes';
import { User } from 'src/common/models/user';
import { AuthErrorOrigin } from './types';
import { v4 as uuid } from 'uuid';

export interface SecurityState {
    user?: User;
    userIsLogged?: boolean;
    error?: any
    createAuthTokenSuccess: boolean
    validateAuthTokenSuccess: boolean
    pageToken?: string
}

const defaultState: SecurityState = {
    createAuthTokenSuccess: false,
    validateAuthTokenSuccess: false,
};

export default (state: SecurityState = defaultState, action: Action): SecurityState => {
    switch (action.type) {
        case Actions.LOGIN_API: {
            return {
                ...state,
                user: undefined,
                error: undefined
            };
        }
        case Actions.LOGIN_SUCCESS: {
            const loginSuccessAction = action as LoginSuccessAction;
            return {
                ...state,
                user: loginSuccessAction.user,
                error: undefined
            };
        }
        case Actions.LOGIN_ERROR: {
            const errorAction = action as ErrorAction;
            return {
                ...state,
                user: undefined,
                error: errorAction.error
            }
        }
        case Actions.LOGOUT_API: {
            return {
                ...state,
                user: undefined,
                error: undefined
            };
        }
        case Actions.CREATE_AUTH_TOKEN_API: {
            return {
                ...state,
                createAuthTokenSuccess: false,
                error: undefined,
            };
        }
        case Actions.CREATE_AUTH_TOKEN_API_SUCCESS: {
            return {
                ...state,
                createAuthTokenSuccess: true,
            };
        }
        case Actions.CREATE_AUTH_TOKEN_API_ERROR: {
            const errorAction = action as ErrorAction;
            return {
                ...state,
                error: {
                    type: AuthErrorOrigin.CREATE_AUTH_TOKEN,
                    detail: errorAction.error
                }
            }
        }
        case Actions.VALIDATE_AUTH_TOKEN_API: {
            return {
                ...state,
                validateAuthTokenSuccess: false,
                error: undefined,
                pageToken: undefined,
            };
        }
        case Actions.VALIDATE_AUTH_TOKEN_API_SUCCESS: {
            return {
                ...state,
                validateAuthTokenSuccess: true,
                pageToken: uuid(),
            };
        }
        case Actions.VALIDATE_AUTH_TOKEN_API_ERROR: {
            const errorAction = action as ErrorAction;
            return {
                ...state,
                error: {
                    type: AuthErrorOrigin.VALIDATE_AUTH_TOKEN,
                    detail: errorAction.error,
                }
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
};