import { ApiError } from "src/features/Security/networking/types";

export enum SaleErrorOrigin {
    CREATE_SALE = 'CREATE_SALE',
    GET_SALE_RECORDS = 'GET_SALE_RECORDS',
    GET_SALE_BY_ID = 'GET_SALE_BY_ID',
    CERTIFY_SALE = 'CERTIFY_SALE',
    GET_SALES_REPORT = 'GET_SALES_REPORT',
    CREATE_SALE_PAYMENT = 'CREATE_SALE_PAYMENT',
    GET_SALE_PAYMENTS = 'GET_SALE_PAYMENTS',
    ABOLISH_SALE = 'ABOLISH_SALE',
}

export interface SaleError {
    type: SaleErrorOrigin,
    detail: ApiError
}