import { Sale } from "src/common/models/sale"
import { Modal } from "antd"
import { CreateSaleForm, SaleProcessType } from "../../CreateSale/CreateSaleForm"
import { SystemDescriptions } from "src/common/descriptions"

interface Props {
  sale: Sale
  open: boolean
  onAccept: () => void
  onCancel: () => void
}

export const SaleDetailModal = (props: Props) => {

  const descriptions = SystemDescriptions.PAGES.SALE.RECORDS

  return (
    <Modal
      title={descriptions.DETAIL_TITLE}
      centered
      open={props.open}
      onOk={props.onAccept}
      onCancel={props.onCancel}
      width={1000}
      footer={null}
      closable={true}
      destroyOnClose
    >
      <CreateSaleForm
        onFinish={() => { }}
        onCancel={() => { }}
        initialValues={props.sale}
        readonly
        processType={SaleProcessType.SALE_DETAIL}
      />
    </Modal>
  )
}
