import { Languages } from "../../types";
import { abolishSaleDictionary } from "./AbolishSale";
import { accountReceivablesDictionary } from "./AccountReceivables";
import { createSaleDictionary } from "./CreateSale";
import { salePaymentsRecordsDictionary } from "./SalePaymentRecords";
import { saleRecordsDictionary } from "./SaleRecords";
import { SalePagesDescriptions } from "./types";

export const getSaleDescriptions = (systemLang: Languages): SalePagesDescriptions => ({
    CREATE: createSaleDictionary[systemLang],
    RECORDS: saleRecordsDictionary[systemLang],
    ACCOUNT_RECEIVABLES: accountReceivablesDictionary[systemLang],
    SALE_PAYMENTS: salePaymentsRecordsDictionary[systemLang],
    ABOLISH_SALE: abolishSaleDictionary[systemLang],
})