import { Sale } from "src/common/models/sale"
import { useDispatch, useSelector } from "react-redux"
import { abolishSaleApi, getSaleByIdApi } from "../state/action"
import { PageCard } from "src/common/styles/styles"
import { CreateSaleForm, SaleProcessType } from "../CreateSale/CreateSaleForm"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { SaleError } from "../state/types"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { ABOLISH_SALE_API, GET_SALE_BY_ID_API } from "../state/actionTypes"
import { RootState } from "src/state/reducer"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { SystemDescriptions } from "src/common/descriptions"
import { ModalSuccess } from "src/components/ModalSuccess"
import { Loader } from "src/components/Loader"
import { Urls } from "src/common/urls"
import { Alert, Button } from "antd"

interface ReduxProps {
    error?: SaleError
    isGettingSale: boolean
    currentSale?: Sale
    abolishSaleSuccess: boolean
    isAbolishingSale: boolean
    pageToken?: string
}

export const AbolishSale = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.sale.error,
        isGettingSale: serviceLoading(state, [GET_SALE_BY_ID_API]),
        currentSale: state.sale.currentSale,
        abolishSaleSuccess: state.sale.abolishSaleSuccess,
        isAbolishingSale: serviceLoading(state, [ABOLISH_SALE_API]),
        pageToken: state.security.pageToken,
    }))

    const { id: saleId } = useParams<{ id: string }>();
    const queryParams = new URLSearchParams(window.location.search);
    const pageToken = queryParams.get('token');

    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [formValues, setFormValues] = useState<Sale | undefined>()
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const descriptions = SystemDescriptions.PAGES.SALE.ABOLISH_SALE
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    useEffect(() => {
        if (!saleId) {
            return
        }

        dispatch(getSaleByIdApi(parseInt(saleId)))
    }, [saleId])

    if (pageToken !== reduxProps.pageToken) {
        return <Alert
            message={commonDescriptions.UNAUTHORIZED_PAGE.TITLE}
            showIcon
            description={commonDescriptions.UNAUTHORIZED_PAGE.DESCRIPTION}
            type="error"
            action={
                <Button
                    size="small"
                    danger
                    onClick={() => {
                        navigate(Urls.FRONTEND.HOME)
                    }}
                >
                    {commonDescriptions.UNAUTHORIZED_PAGE.ACTION_BUTTON}
                </Button>
            }
        />
    }

    const abolishSale = async (sale: Sale) => {
        setFormValues(sale as Sale)
        setModalConfirmationOpen(true)
    }

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)
        // console.log("formvalues", formValues)
        dispatch(abolishSaleApi(
            formValues?.id!,
            formValues?.abolishmentReason!,
        ))
    }

    const handleCancelAccept = () => {
        navigate(Urls.FRONTEND.SALE.RECORDS)
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setFormValues(undefined)
                    setModalConfirmationOpen(false)
                }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={reduxProps.abolishSaleSuccess && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    const renderLoader = () => (
        (reduxProps.isGettingSale || reduxProps.isAbolishingSale)
        && <Loader
            isVisible={true}
            title={descriptions.MAIN_ACTION_TITLE}
            description={descriptions.MAIN_ACTION_DESCRIPTION}
        />
    )

    const onCancel = () => {
        setModalCancelOpen(true)
    }

    return (
        <>
            <PageCard size="small">
                {
                    reduxProps.currentSale &&
                    <CreateSaleForm
                        onFinish={abolishSale}
                        onCancel={onCancel}
                        initialValues={reduxProps.currentSale}
                        readonly
                        reCertification
                        processType={SaleProcessType.ABOLISH_SALE}
                    />
                }
            </PageCard>
            {renderModals()}
            {renderLoader()}
        </>
    )
}
