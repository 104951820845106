import { Modal } from "antd"
import { CreateSaleForm, SaleProcessType } from "../../CreateSale/CreateSaleForm"
import { Sale } from "src/common/models/sale"
import { useDispatch, useSelector } from "react-redux"
import { certifySaleApi } from "../../state/action"
import { Loader } from "src/components/Loader"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { RootState } from "src/state/reducer"
import { CERTIFY_SALE_API } from "../../state/actionTypes"
import { useEffect, useState } from "react"
import { ModalSuccess } from "src/components/ModalSuccess"

interface Props {
    open: boolean
    onAccept: () => void
    onCancel: () => void
    sale: Sale
}

interface ReduxProps {
    isCertifyingSale: boolean
    certifySaleSuccess: boolean
}

export const ReCertificationDTE = (props: Props) => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isCertifyingSale: serviceLoading(state, [CERTIFY_SALE_API]),
        certifySaleSuccess: state.sale.certifySaleSuccess
    }))

    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    useEffect(() => {
        if (reduxProps.certifySaleSuccess) {

        }
    }, [reduxProps.certifySaleSuccess])


    const runRecertifyProcess = async (sale: Sale) => {
        setActionButtonClicked(true)
        dispatch(certifySaleApi(sale))
    }

    return (
        <Modal
            title={"ReCertify DTE Process"}
            centered
            open={props.open}
            onOk={props.onAccept}
            onCancel={props.onCancel}
            width={1000}
            footer={null}
            closable={true}
        >
            <CreateSaleForm
                onFinish={runRecertifyProcess}
                onCancel={props.onCancel}
                initialValues={props.sale}
                readonly
                reCertification
                processType={SaleProcessType.RECERTIFY_DTE}
            />
            <Loader
                isVisible={reduxProps.isCertifyingSale}
            />
            <ModalSuccess
                isVisible={(reduxProps.certifySaleSuccess) && actionButtonClicked}
                title={"Exito"}
                description={"Venta certificada con exito"}
                onPrimaryAction={props.onAccept}
                onSecondaryActiont={props.onAccept}
                primaryLabel={"Aceptar"}
                secondaryLabel={""}
                hideSecondaryButton={true}
            />
        </Modal>
    )
}
