import { ApiActionTypes, buildApiTypeAction } from "src/common/apiLoader/apiLoaderUtils";
import { SystemConstants } from "src/common/constants";

const CREATE_SALE = 'CREATE_SALE';
export const CREATE_SALE_API = CREATE_SALE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_SALE_API_SUCCESS = CREATE_SALE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_SALE_API_ERROR = CREATE_SALE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALE_RECORDS = 'GET_SALE_RECORDS';
export const GET_SALE_RECORDS_API = GET_SALE_RECORDS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALE_RECORDS_API_SUCCESS = GET_SALE_RECORDS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALE_RECORDS_API_ERROR = GET_SALE_RECORDS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALE_BY_ID = 'GET_SALE_BY_ID';
export const GET_SALE_BY_ID_API = GET_SALE_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALE_BY_ID_API_SUCCESS = GET_SALE_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALE_BY_ID_API_ERROR = GET_SALE_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const CLEAR_CURRENT_SALE = 'CLEAR_CURRENT_SALE'

const CERTIFY_SALE = 'CERTIFY_SALE';
export const CERTIFY_SALE_API = CERTIFY_SALE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CERTIFY_SALE_API_SUCCESS = CERTIFY_SALE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CERTIFY_SALE_API_ERROR = CERTIFY_SALE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALES_REPORT = 'GET_SALES_REPORT';
export const GET_SALES_REPORT_API = GET_SALES_REPORT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALES_REPORT_API_SUCCESS = GET_SALES_REPORT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALES_REPORT_API_ERROR = GET_SALES_REPORT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_SALE_PAYMENT = 'CREATE_SALE_PAYMENT';
export const CREATE_SALE_PAYMENT_API = CREATE_SALE_PAYMENT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_SALE_PAYMENT_API_SUCCESS = CREATE_SALE_PAYMENT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_SALE_PAYMENT_API_ERROR = CREATE_SALE_PAYMENT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALE_PAYMENTS = 'GET_SALE_PAYMENTS';
export const GET_SALE_PAYMENTS_API = GET_SALE_PAYMENTS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALE_PAYMENTS_API_SUCCESS = GET_SALE_PAYMENTS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALE_PAYMENTS_API_ERROR = GET_SALE_PAYMENTS + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const CLEAN_SALE_PAYMENTS = 'CLEAN_SALE_PAYMENTS'

const ABOLISH_SALE = 'ABOLISH_SALE';
export const ABOLISH_SALE_API = buildApiTypeAction(ABOLISH_SALE, ApiActionTypes.REQUEST)
export const ABOLISH_SALE_API_SUCCESS = buildApiTypeAction(ABOLISH_SALE, ApiActionTypes.SUCCESS)
export const ABOLISH_SALE_API_ERROR = buildApiTypeAction(ABOLISH_SALE, ApiActionTypes.ERROR)