import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency, formatToDisplayDatetime, } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.PURCHASE.PURHCASE_PAYMENT_RECORDS

export enum ColumnsKeys {
    NO = 'key',
    DATETIME = 'datetime',
    PROVIDER = 'provider',
    DOCUMENT = 'document',
    TOTAL = 'purchaseAmount',
    AMOUNT_PENDING = 'amountPending',
    CASH = 'cashAmount',
    TRANSACTION = 'transAmount',
    CREDIT_NOTE = 'creditNoteAmount',
    ACTIONS = 'actions',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.NO,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.PURCHASE_DOCUMENT,
        dataIndex: ColumnsKeys.DOCUMENT,
        key: ColumnsKeys.DOCUMENT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.SUPPLIER,
        dataIndex: ColumnsKeys.PROVIDER,
        key: ColumnsKeys.PROVIDER,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.PURCHASE_TOTAL,
        dataIndex: ColumnsKeys.TOTAL,
        key: ColumnsKeys.TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value, record) => {
            if (record.statusId === 3) {
                return '0.00'
            }
            return formatToCurrency(value)
        }
    },
    {
        title: descriptions.TABLE_COLUMNS.CASH_AMOUNT,
        dataIndex: ColumnsKeys.CASH,
        key: ColumnsKeys.CASH,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.TRANS_AMOUNT,
        dataIndex: ColumnsKeys.TRANSACTION,
        key: ColumnsKeys.TRANSACTION,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.CREDIT_NOTE_AMOUNT,
        dataIndex: ColumnsKeys.CREDIT_NOTE,
        key: ColumnsKeys.CREDIT_NOTE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.AMOUNT_PENDING,
        dataIndex: ColumnsKeys.AMOUNT_PENDING,
        key: ColumnsKeys.AMOUNT_PENDING,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
];

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.TOTAL]: SummaryType.SUM,
    [ColumnsKeys.AMOUNT_PENDING]: SummaryType.SUM,
    [ColumnsKeys.NO]: SummaryType.COUNT,
}