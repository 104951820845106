import { Modal } from "antd"

import * as Styles from './styles'
import { TokenValidatorForm } from "./TokenValidatorForm"
import { AuthTokenRequest } from "src/common/models/auth"

interface Props {
    purpose: string
    refId: string
    open: boolean
    onCancel: () => void
    onAccept: (authTokenRequest: AuthTokenRequest) => void
}

export const AuthTokenValidator = (props: Props) => {
    const onAccept = () => {
        props.onAccept({
            purpose: props.purpose,
            refId: props.refId,
        })
    }

    const renderContent = () => (
        <Styles.Container>
            <Styles.Description>
                Please enter the auth token sent to the administrator email.
            </Styles.Description>
            <TokenValidatorForm
                onCancel={props.onCancel}
                onAccept={onAccept}
                refId={props.refId}
                purpose={props.purpose}
            />
        </Styles.Container>
    )

    return (
        <>
            <Modal
                centered
                open={props.open}
                width={500}
                footer={null}
                destroyOnClose
                title={"Token Validation"}
                onCancel={props.onCancel}
            >
                {renderContent()}
            </Modal>
        </>
    )
}
