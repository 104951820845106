import { Alert, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceLoading } from 'src/common/apiLoader/state/selection';
import { SystemDescriptions } from 'src/common/descriptions';
import { getInputRequiredRule } from 'src/common/util';
import { Col24 } from 'src/components/Columns';
import { FormActionButtons } from 'src/components/FormActionButtons';
import { validateAuthTokenApi } from 'src/features/Security/state/action';
import { VALIDATE_AUTH_TOKEN_API } from 'src/features/Security/state/actionTypes';
import { AuthError } from 'src/features/Security/state/types';
import { RootState } from 'src/state/reducer';

interface ReduxProps {
    error?: AuthError
    isValidatingAuthToken: boolean
    validateAuthTokenSuccess: boolean
}

interface Props {
    onCancel: () => void
    onAccept: () => void
    purpose: string
    refId: string
}

export const TokenValidatorForm = (props: Props) => {
    const dispatch = useDispatch()

    const [form] = Form.useForm();

    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.security.error,
        isValidatingAuthToken: serviceLoading(state, [VALIDATE_AUTH_TOKEN_API]),
        validateAuthTokenSuccess: state.security.validateAuthTokenSuccess,
    }))

    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    useEffect(() => {
        if (reduxProps.validateAuthTokenSuccess && actionButtonClicked) {
            props.onAccept()
        }
    }, [reduxProps.validateAuthTokenSuccess])


    const onFinish = (formValues) => {
        setActionButtonClicked(true)
        dispatch(validateAuthTokenApi({
            token: formValues.token,
            purpose: props.purpose,
            refId: props.refId,
        }))
    }

    const cleanForm = () => {
        form.resetFields()
    }

    const getButtonStatus = (): boolean => {
        return !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={"Cancel"}
            actionText={"Accept"}
            onCancel={() => {
                cleanForm()
                props.onCancel()
            }}
            actionDisabled={getButtonStatus}
        />
    )

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={onFinish}
        >
            <Row gutter={[16, 0]}>
                <Col24>
                    <Form.Item
                        label={''}
                        name={"token"}
                        validateTrigger="onBlur"
                        rules={
                            [
                                getInputRequiredRule("Token"),
                            ]
                        }
                    >
                        <Input
                            placeholder='Input token'
                        />
                    </Form.Item>
                </Col24>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={""}
                    showIcon
                    description={reduxProps.error?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {renderButtons()}
        </Form>
    )

    return (
        renderForm()
    )
}
