import { Languages } from "src/common/descriptions/types";
import { AbolishSaleDescriptions } from "./types";

const DescriptionsEnUs: AbolishSaleDescriptions = {
    TITLE: "Abolish Sale",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Confirm Sbolish Sale?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Abolish Sale operation?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Print",
        DESCRIPTION: "Sale Abolished successfuly",
    },
    MAIN_ACTION_TITLE: "Processing Sale Abolishing",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
}

const DescriptionsEsUs: AbolishSaleDescriptions = {
    TITLE: "Anular Venta",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Confirmar Anulación de Venta?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar Anulación de Venta?",
        DESCRIPTION: "La información ingresada se perderá.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir trabajando",
    },
    SUCCESS_MODAL: {
        TITLE: "Éxito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Imprimir",
        DESCRIPTION: "Venta Anulada exitosamente",
    },
    MAIN_ACTION_TITLE: "Procesando anulación de venta",
    MAIN_ACTION_DESCRIPTION: "Por favor espera...",
}

export const abolishSaleDictionary = {
    [Languages.ENGLISH_US]: DescriptionsEnUs,
    [Languages.SPANISH_US]: DescriptionsEsUs,
}