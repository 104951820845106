import { Tabs, TabsProps } from "antd";
import { PageCard } from "src/common/styles/styles"
import { CustomerInformation } from "./CustomerInformation";
import { CustomerSalesRecords } from "./CustomerSalesRecords";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";
import { getCustomerByIdApi } from "../state/action";
import { CustomerError } from "../state/types";
import { Customer } from "src/common/models/customer";
import { RootState } from "src/state/reducer";
import { serviceLoading } from "src/common/apiLoader/state/selection";
import { GET_CUSTOMER_BY_ID_API } from "../state/actionTypes";
import InnerLoader from "src/components/InnerLoader";

interface ReduxProps {
    error?: CustomerError
    isGettingCustomer: boolean
    currentCustomer?: Customer
}

export const CustomerProfile = () => {
    const dispatch = useDispatch()

    const { id: customerId } = useParams<{ id: string }>();

    useEffect(() => {
        if (!customerId) {
            return
        }
        dispatch(getCustomerByIdApi(parseInt(customerId)))
    }, [customerId])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isGettingCustomer: serviceLoading(state, [GET_CUSTOMER_BY_ID_API]),
        currentCustomer: state.customer.currentCustomer,
        error: state.customer.error,
    }))

    const items: TabsProps['items'] = [
        {
            key: 'DATA',
            label: 'Information',
            children: (reduxProps.isGettingCustomer && reduxProps.currentCustomer)
                ? <InnerLoader />
                : <CustomerInformation
                    customer={reduxProps.currentCustomer}
                />,
        },
        {
            key: 'SALES_RECORDS',
            label: 'Sales Records',
            children: <CustomerSalesRecords />,
        },
    ];

    const renderContent = () => (
        <Tabs
            defaultActiveKey="DATA"
            items={items}
        />
    )

    return (
        < PageCard
            size="small"
            title={""}
        >
            {renderContent()}
        </PageCard>
    )
}
