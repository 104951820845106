import { ErrorAction } from "src/common/apiLoader/state/actions";
import { CreateRegulariationSuccessAction, GetRegulariationRecordsSuccessAction } from "./action";
import { CREATE_REGULARIZATION_API, CREATE_REGULARIZATION_API_ERROR, CREATE_REGULARIZATION_API_SUCCESS, GET_REGULARIZATION_RECORDS_API, GET_REGULARIZATION_RECORDS_API_ERROR, GET_REGULARIZATION_RECORDS_API_SUCCESS } from "./actionTypes";
import { RegularizationError, RegularizationErrorOrigin } from "./types";
import { StockRegularization } from "src/common/models/stockRegularization";
import { parseDataKey } from "src/common/util";

export interface RegularizationState {
    error?: RegularizationError
    regularizations?: StockRegularization[]
    createRegularizationSuccess: boolean
    createRegularizationResults?: StockRegularization[]
}

const initialState: RegularizationState = {
    createRegularizationSuccess: false,
}

const regularizationReducer = (state: RegularizationState = initialState, action: any): RegularizationState => {
    switch (action.type) {
        case GET_REGULARIZATION_RECORDS_API: {
            return {
                ...state,
                error: undefined,
                regularizations: undefined,
            }
        }
        case GET_REGULARIZATION_RECORDS_API_SUCCESS: {
            const successAction = action as GetRegulariationRecordsSuccessAction;

            return {
                ...state,
                regularizations: parseDataKey(successAction.regularizations),
            }
        }
        case GET_REGULARIZATION_RECORDS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: RegularizationErrorOrigin.GET_REGULARIZATION_RECORDS,
                    detail: errorAction.error
                }
            }
        }
        case CREATE_REGULARIZATION_API: {
            return {
                ...state,
                error: undefined,
                createRegularizationSuccess: false,
                createRegularizationResults: undefined,
            }
        }
        case CREATE_REGULARIZATION_API_SUCCESS: {
            const successAction = action as CreateRegulariationSuccessAction;

            return {
                ...state,
                createRegularizationSuccess: true,
                createRegularizationResults: successAction.regularizations,
            }
        }
        case CREATE_REGULARIZATION_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: RegularizationErrorOrigin.CREATE_REGULARIZATION,
                    detail: errorAction.error,
                }
            }
        }
    }
    return state;
};

export default regularizationReducer;